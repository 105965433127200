


























import { computed, defineComponent } from '@vue/composition-api';
import { IBasicThumbnailAttrs } from './types';
import { iconWhitelist } from './helpers';

export default defineComponent({
  name: 'StoryblokBasicThumbnail',
  components: {
    FCImage: () => import('~/components/FCImage.vue'),
  },
  inheritAttrs: false,
  setup(_, context) {
    const {
      text,
      image,
      icon: icons,
    } = context.attrs as IBasicThumbnailAttrs;

    const thumbnailImage = computed(() => {
      const { filename, alt } = image ?? {};

      return { filename, alt };
    });

    const fcIcon = computed(() => {
      const [icon] = icons ?? [];

      if (icon) {
        const {
          width,
          height,
          iconType,
        } = icon ?? {};

        return {
          iconName: iconWhitelist.get(iconType),
          styles: {
            width,
            height,
          },
        };
      }

      return null;
    });

    return {
      text: computed(() => (text?.length ? text : null)),
      thumbnailImage,
      fcIcon,
      thumbnailClass: computed(() => (text
        ? 'basic-thumbnail__icon-thumbnail fc-flex-col-center fc-bg-white fc-padding-y-8 fc-padding-x-7'
        : 'basic-thumbnail__image-thumbnail fc-flex')),
    };
  },
});
